import BigNumber from 'bignumber.js'
import Vue from 'vue'
import { mapState } from 'vuex'
import Skill from '@/models-ts/Skill'
import { SERVICE_BY_SKILL, SERVICE_DETAILS } from '@/constants/routes'
import { TIME_HOURLY } from '@/constants/backend/service'
import { formatUsdWithCutCents } from '@/utils/moneyFormat'
import { BookmarkTypes } from '@/constants/bookmarks/bookmarkType'
import UserInfo from '@/partials/UserInfo/UserInfo.vue'
import BookmarkIconButton from '@/partials/BookmarkButtons/BookmarkIconButton/BookmarkIconButton.vue'

export default Vue.extend<any, any, any, any>({
  components: { UserInfo, BookmarkIconButton },
  props: {
    gig: Object,
    preview: Boolean,
    adaptive: Boolean,
    withLoader: Boolean,
  },
  data () {
    return {
      SERVICE_DETAILS,
      TIME_HOURLY,
      bookmarkType: BookmarkTypes.GIG,
    }
  },
  computed: {
    ...mapState({
      ownUserId: (state: any) => state.user?.id,
    }),
    isOwn () {
      return this.gig.userId === this.ownUserId
    },
    category () {
      if (this.gig.skills.length) {
        const category = this.gig.skills.find((skill: Skill) => !((skill.relations?.Parent || []).length)) || this.gig.skills[0]
        return {
          id: category.id,
          text: category.name,
          link: this.preview ? null : { name: SERVICE_BY_SKILL, params: { skill: category.url } }
        }
      }
    },
    skillTags () {
      const skills = (this.gig.skills as Array<Skill>)
        .filter(skill => skill.id !== this.category?.id)
        .slice(0, 2)
        .map(skill => ({
          text: skill.name,
          link: this.preview ? null : { name: SERVICE_BY_SKILL, params: { skill: skill.url } }
        }))
      return [this.category, ...skills].filter(Boolean)
    },
    isHourly () {
      return this.gig.timeType === TIME_HOURLY
    },
    rate () {
      let rate = this.gig.rate
      if (this.isHourly) {
        rate = new BigNumber(rate).multipliedBy(this.gig.timeValue || 1)
      }
      return `$${formatUsdWithCutCents(rate, '99.99')}`
    },
    hourlyRate () {
      return `$${formatUsdWithCutCents(this.gig.rate, '99.99')}/hr`
    },
    gigLink () {
      return { name: SERVICE_DETAILS, params: { id: this.gig.id, slug: this.gig.slug } }
    },
    views () {
      if (this.gig.views) {
        return this.gig.views > 1000 ? '1000+' : this.gig.views
      }
    },
    purchases () {
      if (this.gig.completedContractsCount) {
        return this.gig.completedContractsCount > 1000 ? '1000+' : this.gig.completedContractsCount
      }
    },
  },
})
