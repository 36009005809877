import get from 'lodash/get'
import { FIELD_TITLE, FIELD_DESCRIPTION, FIELD_PAGE_HEAD, FIELD_TEXT } from '@/constants/seo/fields'

function getSeo (skill, entityType, fieldType) {
  const seoList = get(skill, 'relations.Seo', [])
  const seo = seoList.find(seo => seo.entity_type === entityType && seo.field_type === fieldType)
  return seo ? seo.value : ''
}

export default {
  methods: {
    getSeoTitle (skill, pageType, defaultValue = '') {
      return getSeo(skill, pageType, FIELD_TITLE) || defaultValue
    },
    getSeoDescription (skill, pageType, defaultValue = '') {
      return getSeo(skill, pageType, FIELD_DESCRIPTION) || defaultValue
    },
    getSeoPageHead (skill, pageType, defaultValue = '') {
      return getSeo(skill, pageType, FIELD_PAGE_HEAD) || defaultValue
    },
    getSeoText (skill, pageType, defaultValue = '') {
      return getSeo(skill, pageType, FIELD_TEXT) || defaultValue
    },
  }
}
