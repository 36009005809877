export enum SeoSkillEntityTypes {
  CONTRACT = 1,
  JOB = 2,
  GIG = 3,
  CUSTOMER = 4,
  FREELANCER = 5,
  SKILL = 6,
}

export enum SeoSkillFieldTypes {
  TITLE = 1,
  DESCRIPTION = 2,
  KEYWORDS = 3,
  PAGE_HEAD = 4,
  TEXT = 5,
}
