export const CAT_COST = 19
export const CAT_COMPETENCE = 20
export const CAT_MOTIVATION = 21
export const CAT_RESPONSIBILITY = 22
export const CAT_COMMUNICATION_SKILLS = 23
export const CAT_REWARD = 24
export const CAT_PROJECT_SCOPE = 25
export const CAT_INVOLVEMENT = 26
export const CAT_FLEXIBILITY = 27

export const REVIEWS_NAME = {
  [CAT_COST]: 'Value',
  [CAT_COMPETENCE]: 'Competence',
  [CAT_MOTIVATION]: 'Initiative',
  [CAT_RESPONSIBILITY]: 'Responsibility',
  [CAT_COMMUNICATION_SKILLS]: 'Communication Skills',
  [CAT_REWARD]: 'Fair Compensation',
  [CAT_PROJECT_SCOPE]: 'Project Scope',
  [CAT_INVOLVEMENT]: 'Involvement',
  [CAT_FLEXIBILITY]: 'Flexibility',
  [CAT_COMMUNICATION_SKILLS]: 'Communication Skills',
}

export const REVIEWS_FREELANCER = [CAT_COST, CAT_COMPETENCE, CAT_MOTIVATION, CAT_RESPONSIBILITY, CAT_COMMUNICATION_SKILLS]
export const REVIEWS_CUSTOMER = [CAT_REWARD, CAT_PROJECT_SCOPE, CAT_INVOLVEMENT, CAT_FLEXIBILITY, CAT_COMMUNICATION_SKILLS]

export const TYPE_CUSTOMER_TO_FREELANCER = 1
export const TYPE_FREELANCER_TO_CUSTOMER = 2
