import Vue, { PropType } from 'vue'
import { mapActions } from 'vuex'
import Skill from '@/models-ts/Skill'
import PrefferedCurrency from '@/models-ts/user/PrefferedCurrency'
import { Blockchain, BLOCKCHAIN_OPTIONS, BlockchainLxIconSrc } from '@/constants/blockchain'
import Currency from '@/models-ts/Currency'

export default Vue.extend<any, any, any, any>({
  props: {
    value: {
      type: Array as PropType<Array<PrefferedCurrency>>,
      default: [],
    },
    editMode: Boolean,
    errorMsg: String,
  },
  data () {
    return {
      currencies: [],
    }
  },
  computed: {
    currenciesByBlockchain () {
      return BLOCKCHAIN_OPTIONS.map((option: { name: string, value: Blockchain }) => ({
        blockchain: option.value, currencies: this.getTags(option.value)
      }))
    },
    isEmpty () {
      return !this.currenciesByBlockchain.some((item: any) => item.currencies.length)
    },
    tags: {
      get () {
        return this.currencies.map((skill: Skill) => ({ text: skill.name }))
      }
    },
  },
  watch: {
    value: {
      handler () {
        this.initForm()
      },
      immediate: true
    },
    editMode () {
      if (this.editMode) {
      }
    }
  },
  methods: {
    ...mapActions({
      openModal: 'ui/openModal',
    }),
    getTags (blockchain: Blockchain) {
      return this.currencies
        .filter((c: PrefferedCurrency) => c.blockchain === blockchain)
        .map((c: PrefferedCurrency) => ({ text: c.name }))
    },
    initForm () {
      if (this.value.length) {
        this.currencies = [...this.value]
      }
    },
    getBlochainIcon (blockchain: Blockchain) {
      return BlockchainLxIconSrc[blockchain]
    },
    onDelete (blockchain: Blockchain, { tag }: { tag: CloudyTag }) {
      const index = this.currencies.findIndex((curr: Currency) => curr.blockchain === blockchain && curr.name === tag.text)
      if (index !== -1) {
        this.currencies.splice(index, 1)
      }
      this.$emit('input', this.currencies)
    },
    onAddClick () {
      this.openModal({
        component: 'lx-lazy-modal',
        props: {
          factory: import(/* webpackChunkName: "skills-modals" */ '@/modals/CurrencySelection/CurrencySelection.vue'),
          title: 'Preferred payment options',
          props: {
            currencies: this.currencies,
            onSuccess: (currencies: Array<PrefferedCurrency>) => {
              this.currencies = currencies
              this.$emit('input', this.currencies)
            }
          }
        }
      })
    },
  }
})
