import { AccountTypes } from '@/constants/user/accountTypes'
import { CUSTOMER_PROFILE, FREELANCER_PROFILE } from '@/constants/routes'
import { Avatar } from '@/models/user'
import UserVerificationBadge from '@/partials/StatusBadges/UserVerificationBadge/UserVerificationBadge.vue'
import Vue, { PropType } from 'vue'

export default Vue.extend({
  components: {
    UserVerificationBadge
  },
  props: {
    id: [String, Number],
    avatar: Avatar,
    userType: Number as PropType<AccountTypes>,
    name: {
      type: String,
      default: 'Anonymous',
    },
    avgReviews: [String, Number],
    reviewsCount: [String, Number],
    isFreelancer: Boolean,
    isCustomerVerified: Boolean,
    customerType: Number,
  },
  computed: {
    avgReviewsFixed () {
      return Number(this.avgReviews || 0).toFixed(0)
    },
    reviewsCountFixed () {
      return Number(this.reviewsCount || 0).toFixed(0)
    },
    userLink () {
      return this.isFreelancer
        ? { name: FREELANCER_PROFILE, params: { id: this.id } }
        : { name: CUSTOMER_PROFILE, params: { id: this.id } }
    },
  },
})
