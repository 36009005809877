import Vue from 'vue'
import meanBy from 'lodash/meanBy'
import Review from '@/models-ts/user/Review'
import { formatDate } from '@/utils/date'
import { DATE_FORMAT } from '@/constants/utils/date'
import { REVIEWS_CUSTOMER, REVIEWS_FREELANCER, REVIEWS_NAME, TYPE_CUSTOMER_TO_FREELANCER } from '@/constants/components/review'
import UserInfo from '@/partials/UserInfo/UserInfo.vue'

export default Vue.extend<any, any, any, any>({
  components: {
    UserInfo
  },
  props: {
    review: Review,
  },
  data () {
    return {
      expanded: false,
    }
  },
  computed: {
    reviewFromCustomer () {
      return this.review.type === TYPE_CUSTOMER_TO_FREELANCER
    },
    createdAt () {
      return formatDate(this.review.createdAt, DATE_FORMAT)
    },
    grades () {
      return this.reviewFromCustomer ? REVIEWS_FREELANCER : REVIEWS_CUSTOMER
    },
    avg () {
      return Number((meanBy(this.review.ratings, 'rate') || 0).toFixed(1))
    },
  },
  methods: {
    getRate (category: number) {
      const rateData = this.review.ratings.find((rate: any) => rate.category === category)
      return rateData ? rateData.rate : 0
    },
    getStatTitle (category: number) {
      // @ts-ignore
      return REVIEWS_NAME[category]
    },
  }
})
