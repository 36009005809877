import { render, staticRenderFns } from "./UserInfo.htm?vue&type=template&id=857c97b8&scoped=true&"
import script from "./UserInfo.ts?vue&type=script&lang=js&"
export * from "./UserInfo.ts?vue&type=script&lang=js&"
import style0 from "./UserInfo.scss?vue&type=style&index=0&id=857c97b8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "857c97b8",
  null
  
)

export default component.exports